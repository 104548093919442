import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {useTheme} from '@material-ui/core/styles';

import {StarIcon} from 'src/componentsNext/icons/reviews/StarIcon';
import {StarSimpleIcon} from 'src/componentsNext/icons/reviews/StarSimpleIcon';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 16,
    gap: 4,
    marginTop: theme.spacing(0.5)
  },
  starIcon: {
    display: 'flex',
    width: 16,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2
  }
}));

export const ReviewsCustomRating = ({rating}) => {
  const classes = useStyles();
  return (
    <Rating
      classes={{root: classes.root}}
      value={rating}
      precision={0.5}
      readOnly
      icon={<StarSimpleIcon width={16} height={16} />}
      emptyIcon={<StarSimpleIcon width={16} height={16} />}
    />
  );
};

ReviewsCustomRating.propTypes = {
  rating: PropTypes.number
};
